import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import './Header.css';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

    handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
      this.setState({ anchorEl: null });
    }

    render = () => (
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className="title">
            { process.env.REACT_APP_APPLICATION_NAME || 'Ballista' }
          </Typography>
          <div className="userSection">
            <Link
              aria-controls="customized-menu"
              aria-haspopup="true"
              component="button"
              variant="body2"
              color="inherit"
              onClick={this.handleClick}
            >
              <FontAwesomeIcon icon={faUser} size="1x" color="lightgray" className="userIcon" />
              {this.props.auth && this.props.auth.username ? this.props.auth.username : 'User'}
            </Link>
            <StyledMenu
              id="customized-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              <StyledMenuItem onClick={this.props.handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} size="1x" color="lightgray" className="userIcon" />
                <ListItemText primary="Logout" />
              </StyledMenuItem>
            </StyledMenu>
          </div>
        </Toolbar>
      </AppBar>
    );
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default Header;
